import React from "react"
import { RichText } from "prismic-reactjs"
import styled, { css } from "styled-components"
import { font } from "../cssVariables"

const HeroWrapper = styled.section`
  ${props =>
    !props.isVideo &&
    css`
    background-image: url('${props => props.bgMobile}');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  `}
  height: 600px;
  color: white;
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;

  @media (min-width: 768px) {
    height: 640px;

    ${props =>
      !props.isVideo &&
      css`
        background-image: url('${props => props.bgTablet}');
      `}
  }

  @media (min-width: 1200px) {
    height: 798px;

    ${props =>
      !props.isVideo &&
      css`
        background-image: url('${props => props.bgDesktop}');
      `}
  }

  @media (min-width: 1920px) {
    height: 900px;
  }

  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 100;
    position: absolute;
  }

  .hero {
    &-content {
      position: relative;
      z-index: 200;
      top: 20px;
      width: 100%;

      @media (min-width: 768px) {
        top: 55px;
      }
    }

    &__headline {
      h1,
      h2 {
        font-family: ${font.uiDisplay};
        text-transform: uppercase;
        font-weight: bold;
        font-size: 40px;
        letter-spacing: 0.02em;
        line-height: 38px;
        text-align: left;
        color: #fff;
        margin-bottom: 12px;

        @media (min-width: 768px) {
          font-size: 74px;
          line-height: 72px;
        }

        @media (min-width: 1200px) {
          font-size: 86px;
          line-height: 84px;
          margin-bottom: 20px;
          max-width: 740px;
        }
      }
    }

    &__copy {
      p {
        font-family: ${font.uiText};
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        text-align: left;
        color: #fff;
        max-width: 85%;
        margin-bottom: 0;

        @media (min-width: 768px) {
          font-size: 18px;
          line-height: 26px;
          max-width: 560px;
        }
        @media (min-width: 1200px) {
          font-size: 22px;
          line-height: 32px;
          max-width: 660px;
        }
      }
    }
  }
`

const Hero = ({
  id,
  headline,
  copy,
  bgDesktop,
  bgTablet,
  bgMobile,
  bgVideoMp4 = null,
  bgVideoWebm = null,
}) => {
  const isClient = typeof window !== "undefined"

  return (
    <HeroWrapper
      id={id}
      bgDesktop={bgDesktop}
      bgTablet={bgTablet}
      bgMobile={bgMobile}
      isVideo={bgVideoMp4 !== null}
    >
      {isClient && bgVideoMp4 && (
        <video
          autoPlay
          muted
          loop
          playsInline
          poster={
            window.screen.width < 768
              ? bgMobile
              : window.screen.width >= 768 && window.screen.width < 1200
              ? bgTablet
              : bgDesktop
          }
        >
          <source src={bgVideoMp4} type="video/mp4" />
          <source src={bgVideoWebm} type="video/webm" />
        </video>
      )}

      <div className="container hero-content">
        <div className="hero__headline">
          <RichText render={headline} />
        </div>
        <div className="hero__copy">
          <RichText render={copy} />
        </div>
      </div>
    </HeroWrapper>
  )
}

export default Hero
