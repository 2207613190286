import React from "react"
import styled from "styled-components"

const ImageBlockWrapper = styled.section`
  background-image: url('${props => props.image.mobile.url}');
  height: ${props => props.image.mobile.dimensions.height / 2}px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  color: white;

  @media (min-width: 768px) {
    background-image: url('${props => props.image.tablet.url}');
    height: ${props => props.image.tablet.dimensions.height / 2}px;
  }

  @media (min-width: 1200px) {
    background-image: url('${props => props.image.url}');
    height: ${props => props.image.dimensions.height / 2}px;
  }
`

const ImageBlock = ({ label, image, id }) => {
  return <ImageBlockWrapper id={id} image={image}></ImageBlockWrapper>
}

export default ImageBlock
