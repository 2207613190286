import React from "react"
import styled from "styled-components"
import { colors } from "../cssVariables"
import GoogleMapReact from "google-map-react"
import MapPinIcon from "../../images/map-pin-solid.svg"
import mapStyles from "./map_style.json"

const isClient = typeof window !== "undefined"

const MapWrapper = styled.section`
  color: ${colors.white};
  background-color: ${colors.darkBlue};

  .map {
    height: 290px;
    width: 100%;
    padding-top: 35px;

    @media (min-width: 768px) {
      height: 350px;
      padding-top: 45px;
    }
    @media (min-width: 1200px) {
      height: 500px;
      padding-top: 55px;
    }

    &-marker {
      width: 20px;
      img {
        margin: 0;
      }
    }
  }
`

const LogicMarker = ({ icon }) => (
  <div className="map-marker">
    <img src={icon} alt="Logic London" />
  </div>
)

const Map = ({ latitude, longitude }) => {
  return (
    <MapWrapper>
      <div className="container">
        <div className="map">
          {isClient && (
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyDYPPscnafxTbuFMgZ5L5mbCYHjH7uk-8U",
              }}
              defaultCenter={[latitude, longitude]}
              defaultZoom={14}
              options={{ styles: mapStyles }}
            >
              <LogicMarker lat={latitude} lng={longitude} icon={MapPinIcon} />
            </GoogleMapReact>
          )}
        </div>
      </div>
    </MapWrapper>
  )
}

export default Map
