import React from "react"
import { RichText } from "prismic-reactjs"
import styled from "styled-components"
import { colors, font } from "../cssVariables"

const QuoteWrapper = styled.section`
  color: ${colors.white};
  background-color: ${colors.darkBlue};
  padding-top: 100px;
  padding-bottom: 80px;

  @media (min-width: 768px) {
    padding-top: 185px;
    padding-bottom: 152px;
  }

  .quote {
    &__quote {
      max-width: 326px;
      margin: 0 auto 15px;

      @media (min-width: 768px) {
        max-width: 550px;
      }

      @media (min-width: 1200px) {
        max-width: 1208px;
      }

      p {
        font-family: ${font.uiText};
        font-weight: normal;
        font-size: 20px;
        line-height: 31px;
        text-align: center;
        color: #fff;
        width: 100%;

        @media (min-width: 768px) {
          font-size: 28px;
          line-height: 42px;
        }

        @media (min-width: 1200px) {
          font-size: 32px;
          line-height: 50px;
        }
      }
    }

    &__author {
      max-width: 326px;
      margin: 0 auto;

      @media (min-width: 768px) {
        max-width: 550px;
      }

      p {
        text-align: center;
        font-size: 12px;
        line-height: 22px;
        text-align: center;
        color: #fff;
        margin-bottom: 0;

        @media (min-width: 768px) {
          font-size: 14px;
          line-height: 26px;
        }

        @media (min-width: 1200px) {
          font-size: 16px;
          line-height: 30px;
        }
      }
    }
  }
`

const Quote = ({ quote, author, id }) => {
  return (
    <QuoteWrapper id={id}>
      <div className="container">
        <div className="quote__quote">
          <RichText render={quote} />
        </div>
        <div className="quote__author">
          <RichText render={author} />
        </div>
      </div>
    </QuoteWrapper>
  )
}

export default Quote
