import React from "react"
import styled from "styled-components"
import { colors } from "../cssVariables"
import { useStaticQuery, graphql } from "gatsby"
import SectionIntro from "../sectionIntro"

const LogoGalleryWrapper = styled.section`
  background-color: ${colors.mainColor};
  padding-top: 80px;
  padding-bottom: 52px;

  @media (min-width: 768px) {
    padding-top: 128px;
    padding-bottom: 100px;
  }

  @media (min-width: 1200px) {
    padding-top: 200px;
    padding-bottom: 156px;
  }

  .container {
    @media (min-width: 1200px) {
      display: flex;
      justify-content: space-between;
    }
  }

  .logos-content {
    @media (min-width: 1200px) {
      flex: 0 0 760px;
    }
    @media (min-width: 1400px) {
      flex: 0 0 840px;
    }
  }

  .logos-clients {
    display: flex;
    flex-wrap: wrap;

    &__item {
      display: flex;
      align-items: center;
      height: 100%;
      flex: 0 0 calc((100% / 3) - 5px);
      background-color: ${props =>
        props.addBackgroundColor ? colors.darkBlue : "transparent"};
      margin-bottom: 5px;
      margin-left: 2.5px;
      margin-right: 2.5px;

      @media (min-width: 576px) {
        flex: 0 0 calc((100% / 4) - 10px);
      }

      @media (min-width: 768px) {
        flex: 0 0 calc((100% / 5) - 5px);
      }

      img {
        margin: 0;
      }
    }
  }

  .logos-agencies {
    margin-top: 35px;

    @media (min-width: 1200px) {
      margin-top: 60px;
      width: 92%;
      margin-left: auto;
      margin-right: auto;
    }

    &__section-label {
      font-weight: bold;
      font-size: 10px;
      letter-spacing: 0.02em;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 25px;

      @media (min-width: 768px) {
        text-align: left;
      }
    }

    &__items {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    &__item {
      height: 100%;
      flex: 0 0 calc((100% / 3) - 5px);
      margin-left: 2.5px;
      margin-right: 2.5px;
      background-color: ${props =>
        props.agencieshasBackground ? colors.darkBlue : "transparent"};
      margin-bottom: 3px;
      display: flex;
      align-items: center;

      @media (min-width: 576px) {
        flex: 0 0 calc((100% / 4) - 10px);
        margin-left: 2.5px;
        margin-right: 2.5px;
      }

      @media (min-width: 576px) {
        flex: 0 0 calc((100% / 4) - 10px);
      }

      @media (min-width: 768px) {
        text-align: left;
        flex: 0 0 calc((100% / 5) - 5px);
      }

      img {
        margin: 0;
      }
    }
  }
`

const LogoGallery = ({
  id,
  body,
  title,
  headline,
  hasBackground,
  logos,
  ctaLabel,
  ctaLink,
  ctaStyle,
}) => {
  const data = useStaticQuery(graphql`
    {
      prismic {
        allHomepages {
          edges {
            node {
              body {
                ... on PRISMIC_HomepageBodyLogos {
                  type
                  label
                  fields {
                    logo_image
                  }
                  primary {
                    add_background_color
                    section_label
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const dataHomepage = data.prismic.allHomepages.edges[0].node.body
  const logosAgencies = dataHomepage.filter(slice => {
    return slice.type === "logos"
  })[0]

  return (
    <LogoGalleryWrapper
      id={id}
      hasBackground={hasBackground}
      agencieshasBackground={logosAgencies.primary.add_background_color}
    >
      <div className="container wrapper">
        <SectionIntro
          title={title}
          headline={headline}
          ctaLabel={ctaLabel}
          ctaLink={ctaLink}
          ctaStyle={ctaStyle}
        />

        <div className="logos-content">
          <div className="logos-clients">
            {logos.map((logo, i) => (
              <div className="logos-clients__item" key={i}>
                <img src={logo.logo_image.url} alt={logo.alt} />
              </div>
            ))}
          </div>

          {logosAgencies.fields.length && (
            <div className="logos-agencies">
              <div className="logos-agencies__section-label">
                {logosAgencies.primary.section_label}
              </div>
              <div className="logos-agencies__items">
                {logosAgencies.fields.map((logo, i) => (
                  <div className="logos-agencies__item" key={i}>
                    <img src={logo.logo_image.url} alt={logo.alt} />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </LogoGalleryWrapper>
  )
}

export default LogoGallery
