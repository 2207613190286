import React from "react"
import { RichText } from "prismic-reactjs"
import styled from "styled-components"
import SectionIntro from "../sectionIntro"
import { colors } from "../cssVariables"

const SectionWrapper = styled.section`
  color: ${props => (props.theme === "dark" ? colors.white : colors.mainColor)};
  background-color: ${props =>
    props.theme === "dark" ? colors.mainColor : colors.white};
  padding-top: 80px;
  padding-bottom: 52px;

  @media (min-width: 768px) {
    padding-top: 128px;
    padding-bottom: 100px;
  }

  @media (min-width: 1200px) {
    padding-top: 200px;
    padding-bottom: 156px;
  }

  .container {
    @media (min-width: 1200px) {
      display: flex;
      justify-content: space-between;
    }
  }

  .section {
    &__content {
      @media (min-width: 1200px) {
        flex: 0 0 720px;
      }
      @media (min-width: 1400px) {
        flex: 0 0 800px;
      }
    }

    &__paragraph {
      p {
        color: ${props =>
          props.theme === "dark" ? colors.white : colors.mainColor};
      }
    }

    &__columns {
      @media (min-width: 768px) {
        display: flex;
        justify-content: space-between;
      }

      ul {
        margin-left: 15px;
        margin-top: 20px;
      }

      li {
        color: ${props =>
          props.theme === "dark" ? colors.white : colors.mainColor};
        margin-bottom: 0;
      }

      &-col {
        @media (min-width: 768px) {
          flex: 0 0 325px;
        }
        @media (min-width: 1200px) {
          flex: 0 0 calc((100% / 2) - 26px);
        }
      }
    }
  }
`

const IntroOneColumnText = ({
  id,
  title,
  headline,
  ctaLabel,
  ctaLink,
  ctaStyle,
  theme,
  firstColumnText,
  secondColumnText,
  content,
}) => {
  return (
    <SectionWrapper theme={theme} id={id}>
      <div className="container">
        <SectionIntro
          title={title}
          headline={headline}
          theme={theme}
          ctaLabel={ctaLabel}
          ctaLink={ctaLink}
          ctaStyle={ctaStyle}
        />

        <div className="section__content">
          <div className="section__paragraph">
            <RichText render={content} />
          </div>

          <div className="section__columns">
            <div className="section__columns-col">
              <RichText render={firstColumnText} />
            </div>
            <div className="section__columns-col">
              <RichText render={secondColumnText} />
            </div>
          </div>
        </div>
      </div>
    </SectionWrapper>
  )
}

export default IntroOneColumnText
