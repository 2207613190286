import React from "react"
import { RichText } from "prismic-reactjs"
import styled from "styled-components"
import SectionIntro from "../sectionIntro"
import { colors, font } from "../cssVariables"

const OurServicesWrapper = styled.section`
  color: ${colors.white};
  background-color: ${colors.mainColor};
  padding-top: 80px;
  padding-bottom: 52px;

  @media (min-width: 768px) {
    padding-top: 128px;
    padding-bottom: 100px;
  }

  @media (min-width: 1200px) {
    padding-top: 200px;
    padding-bottom: 130px;
  }

  .container {
    @media (min-width: 1200px) {
      display: flex;
      justify-content: space-between;
    }
  }

  .our-service {
    &__content {
      @media (min-width: 1200px) {
        flex: 0 0 720px;
      }
      @media (min-width: 1400px) {
        flex: 0 0 800px;
      }
    }

    &__services {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &__item {
      background-color: ${colors.darkBlue};
      flex: 0 0 100%;
      padding: 55px 55px 60px;
      margin-bottom: 20px;
      text-align: center;

      @media (min-width: 768px) {
        flex: 0 0 335px;
        padding: 56px 35px;
        margin-bottom: 48.5px;
      }

      @media (min-width: 768px) {
        flex: 0 0 calc((100% / 2) - 25px);
        margin-bottom: 50px;
      }

      @media (min-width: 1200px) {
        margin-bottom: 48.5px;
        padding: 70px 35px;
      }

      img {
        width: 72px;

        @media (min-width: 768px) {
          width: 90px;
        }

        @media (min-width: 1200px) {
          width: 105px;
        }
      }

      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: ${font.uiText};
        font-weight: bold;
        font-size: 16px;
        line-height: 38px;
        text-align: center;
        color: ${colors.white};

        @media (min-width: 768px) {
          font-size: 18px;
        }
        @media (min-width: 1200px) {
          font-size: 20px;
        }
      }

      p {
        margin-bottom: 0;
        color: ${colors.lightGray};
      }
    }
  }
`

const OurServices = ({
  id,
  title,
  headline,
  services,
  ctaLabel,
  ctaLink,
  ctaStyle,
}) => {
  return (
    <OurServicesWrapper id={id}>
      <div className="container">
        <SectionIntro
          title={title}
          headline={headline}
          ctaLabel={ctaLabel}
          ctaLink={ctaLink}
          ctaStyle={ctaStyle}
        />

        <div className="our-service__content">
          <div className="our-service__services">
            {services.map((item, i) => (
              <div className="our-service__item" key={i}>
                <img src={item.item_icon.url} alt={item.item_icon.alt} />
                <RichText render={item.item_title} />
                <RichText render={item.item_copy} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </OurServicesWrapper>
  )
}

export default OurServices
