import React from "react"
import { RichText } from "prismic-reactjs"
import styled from "styled-components"
import { font, colors } from "./cssVariables"
import arrowIcon from "../images/icon-arrow-right.svg"

const SectionIntroWrapper = styled.section`
  .section__intro {
    margin-bottom: 50px;

    @media (min-width: 768px) {
      margin-bottom: 90px;
    }
    @media (min-width: 1200px) {
      margin-bottom: 0;
    }

    &-title {
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: ${props =>
          props.theme === "dark" ? colors.white : colors.mainColor};
        font-family: ${font.uiText};
        font-weight: bold;
        font-size: 12px;
        letter-spacing: 0.02em;
        text-align: left;
        margin-bottom: 20px;
        text-transform: uppercase;

        @media (min-width: 1200px) {
          font-size: 14px;
          margin-bottom: 36px;
        }
      }
    }

    &-headline {
      max-width: 315px;

      @media (min-width: 768px) {
        max-width: 440px;
      }
      @media (min-width: 1200px) {
        max-width: 296px;
      }

      p {
        font-family: ${font.uiText};
        font-weight: normal;
        font-size: 26px;
        line-height: 29px;
        color: ${props =>
          props.theme === "dark" ? colors.white : colors.mainColor};
        text-align: left;
        margin-bottom: 24px;

        @media (min-width: 768px) {
          font-size: 28px;
          line-height: 34px;
        }
        @media (min-width: 1200px) {
          font-size: 32px;
          line-height: 38px;
          margin-bottom: 32px;
        }
      }
    }

    .intro__cta {
      display: inline-block;

      &--button {
        font-family: ${font.uiText};
        font-weight: bold;
        font-size: 12px;
        letter-spacing: 0.02em;
        text-align: left;
        background-color: ${colors.blue};
        color: ${colors.white};
        text-transform: uppercase;
        text-decoration: none;
        position: relative;
        padding: 12px 30px;
        border-radius: 2px;

        @media (min-width: 1200px) {
          font-size: 14px;
        }
      }

      &--link {
        font-family: ${font.uiText};
        font-weight: bold;
        font-size: 12px;
        letter-spacing: 0.02em;
        text-align: left;
        color: ${colors.blue};
        text-transform: uppercase;
        text-decoration: none;
        position: relative;

        @media (min-width: 1200px) {
          font-size: 14px;
        }

        &:hover {
          &:after {
            left: 20px;
          }
        }

        &:after {
          transition: 0.4s;
          content: "";
          top: 0;
          left: 15px;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          position: relative;
          display: inline-block;
          background-image: url(${arrowIcon});
          width: 12px;
          height: 10px;
        }
      }
    }
  }
`

const SectionIntro = ({
  title,
  headline,
  theme = "dark",
  ctaLabel,
  ctaLink,
  ctaStyle,
}) => {
  return (
    <SectionIntroWrapper theme={theme}>
      <div className="section__intro">
        <div className="section__intro-title">
          <RichText render={title} />
        </div>
        <div className="section__intro-headline">
          <RichText render={headline} />
        </div>
        {ctaLabel && ctaLink && (
          <div className="section__intro-headline">
            <a href={ctaLink} className={`intro__cta intro__cta--${ctaStyle}`}>
              {ctaLabel}
            </a>
          </div>
        )}
      </div>
    </SectionIntroWrapper>
  )
}

export default SectionIntro
