import React from "react"
import Hero from "./slices/hero"
import OurServices from "./slices/ourServices"
import Quote from "./slices/quote"
import IntroTwoColumnsText from "./slices/introTwoColumnsText"
import IntroOneColumnText from "./slices/introOneColumnText"
import ImageBlock from "./slices/imageBlock"
import OurClients from "./slices/ourClients"
import Contact from "./slices/contact"
import Map from "./slices/map"

const SliceZone = ({ body }) => {
  return (
    <div>
      {body.map((slice, i) => {
        switch (slice.type) {
          case "hero":
            return (
              <Hero
                id={slice.primary.id}
                headline={slice.primary.hero_headline}
                copy={slice.primary.hero_copy}
                bgVideoWebm={slice.primary.bg_video_webm}
                bgVideoMp4={slice.primary.bg_video_mp4}
                bgDesktop={slice.primary.bg_image.url}
                bgTablet={slice.primary.bg_image.tablet.url}
                bgMobile={slice.primary.bg_image.mobile.url}
                key={i}
              />
            )

          case "our_services":
            return (
              <OurServices
                id={slice.primary.id}
                title={slice.primary.sectionTitle}
                headline={slice.primary.headline}
                ctaLabel={slice.primary.cta_button_label}
                ctaLink={slice.primary.cta_button_link}
                ctaStyle={slice.primary.cta_button_style}
                services={slice.fields}
                key={i}
              />
            )

          case "quote":
            return (
              <Quote
                id={slice.primary.id}
                quote={slice.primary.quote}
                author={slice.primary.author}
                key={i}
              />
            )

          case "intro___two_columns_text":
            return (
              <IntroTwoColumnsText
                id={slice.primary.id}
                title={slice.primary.section_title}
                headline={slice.primary.headline}
                ctaLabel={slice.primary.cta_button_label}
                ctaLink={slice.primary.cta_button_link}
                ctaStyle={slice.primary.cta_button_style}
                theme={slice.primary.theme}
                firstColumnText={slice.primary.first_column_text}
                secondColumnText={slice.primary.second_column_text}
                key={i}
              />
            )

          case "intro___one_column_text_plus_two_columns":
            return (
              <IntroOneColumnText
                id={slice.primary.id}
                title={slice.primary.section_title}
                headline={slice.primary.headline}
                ctaLabel={slice.primary.cta_button_label}
                ctaLink={slice.primary.cta_button_link}
                ctaStyle={slice.primary.cta_button_style}
                theme={slice.primary.theme}
                content={slice.primary.content}
                firstColumnText={slice.primary.first_column_text}
                secondColumnText={slice.primary.second_column_text}
                key={i}
              />
            )

          case "image_block":
            return (
              <ImageBlock
                id={slice.primary.id}
                label={slice.label}
                image={slice.primary.image}
                key={i}
              />
            )

          case "our_clients":
            return (
              <OurClients
                id={slice.primary.id}
                title={slice.primary.our_clients_title}
                headline={slice.primary.section_headline}
                ctaLabel={slice.primary.cta_button_label}
                ctaLink={slice.primary.cta_button_link}
                ctaStyle={slice.primary.cta_button_style}
                hasBackground={slice.primary.add_background_color}
                logos={slice.fields}
                key={i}
              />
            )

          case "google_map":
            return (
              <Map
                latitude={slice.primary.map.latitude}
                longitude={slice.primary.map.longitude}
                key={i}
              />
            )

          case "contact":
            return (
              <Contact
                id={slice.primary.id}
                scriptTags={slice.primary.script}
                key={i}
              />
            )

          default:
            return null
        }
      })}
    </div>
  )
}

export default SliceZone
