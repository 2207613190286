import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SliceZone from "../components/sliceZone"

import GlobalStyles from "../components/globalStyles"
import GlobalFonts from "../fonts/fonts"

const IndexPage = props => {
  return (
    <Layout>
      <SEO title="Home" />
      <GlobalStyles />
      <GlobalFonts />
      <SliceZone body={props.data.prismic.allHomepages.edges[0].node.body} />
    </Layout>
  )
}

export const query = graphql`
  {
    prismic {
      allHomepages {
        edges {
          node {
            body {
              ... on PRISMIC_HomepageBodyHero {
                type
                primary {
                  id
                  hero_headline
                  hero_copy
                  bg_image
                  bg_video_mp4
                  bg_video_webm
                }
              }
              ... on PRISMIC_HomepageBodyQuote {
                type
                label
                primary {
                  id
                  author
                  quote
                }
              }
              ... on PRISMIC_HomepageBodyOur_services {
                type
                label
                primary {
                  id
                  cta_button_label
                  cta_button_link
                  cta_button_style
                  headline
                  sectionTitle
                }
                fields {
                  item_copy
                  item_icon
                  item_title
                }
              }
              ... on PRISMIC_HomepageBodyIntro___two_columns_text {
                type
                label
                primary {
                  id
                  headline
                  cta_button_label
                  cta_button_link
                  cta_button_style
                  first_column_text
                  second_column_text
                  section_title
                  theme
                }
              }
              ... on PRISMIC_HomepageBodyIntro___one_column_text_plus_two_columns {
                type
                label
                primary {
                  id
                  content
                  section_title
                  headline
                  cta_button_label
                  cta_button_link
                  cta_button_style
                  first_column_text
                  second_column_text
                  theme
                }
              }
              ... on PRISMIC_HomepageBodyImage_block {
                type
                label
                primary {
                  id
                  image
                  imageSharp {
                    id
                  }
                }
              }
              ... on PRISMIC_HomepageBodyOur_clients {
                type
                primary {
                  id
                  add_background_color
                  section_headline
                  our_clients_title
                  cta_button_label
                  cta_button_link
                  cta_button_style
                }
                fields {
                  logo_image
                }
              }
              ... on PRISMIC_HomepageBodyContact {
                type
                label
                primary {
                  id
                  script
                }
              }
              ... on PRISMIC_HomepageBodyGoogle_map {
                type
                label
                primary {
                  map
                }
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
