import React from "react"
import styled from "styled-components"
import { colors } from "../cssVariables"

const ContactWrapper = styled.section`
  color: ${colors.white};
  background-color: ${colors.darkBlue};

  .contact__box {
    background-color: ${colors.mainColor};
    min-height: 500px;
    width: 100%;
  }
`

const Contact = ({ id, scriptTags }) => {
  return (
    <ContactWrapper id={id}>
      <div className="container">
        <div
          className="contact__box"
          dangerouslySetInnerHTML={{ __html: scriptTags[0].text }}
        ></div>
      </div>
    </ContactWrapper>
  )
}

export default Contact
